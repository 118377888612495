const AGE_GATE_KEY = "age_gate";
const AGE_GATE_COMPLETED = "age-gate_completed";
const AGE_GATE_CONTAINER = ".age-gate-container";
const AGE_GATE_CONFIG = "#age-gate-config";
const DAY_SELECTOR = ".age-gate-dob__dropdown--day input";
const MONTH_SELECTOR = ".age-gate-dob__dropdown--month input";
const YEAR_SELECTOR = ".age-gate-dob__dropdown--year input";
const SUBMIT_SELECTOR = ".age-gate-confirm a";
const AGE_GATE_HIDDEN = "age-gate-hidden";
const ONE_TRUST_SCAN = 'otscan'
const HTML_TAG = 'html';
const MAIN_SCROLLER_CLASS = 'main-scroller';

const setAgeGateSession = (result) => {
  sessionStorage.setItem(AGE_GATE_KEY, result);
  sessionStorage.setItem(AGE_GATE_COMPLETED, true);
}

const convertToBoolean = (str) => {
  return !!(str === 'true');
}

const hasPassedAgeGate = () => {
  return convertToBoolean(sessionStorage.getItem(AGE_GATE_KEY)) && convertToBoolean(sessionStorage.getItem(AGE_GATE_COMPLETED));
}

const hasFailedAgeGate = () => {
  return !convertToBoolean(sessionStorage.getItem(AGE_GATE_KEY)) && convertToBoolean(sessionStorage.getItem(AGE_GATE_COMPLETED));
}

const hasAgeGate = () => {
  return document.querySelector(AGE_GATE_CONTAINER) !== null;
}

const getAgeGateErrorPage = () => {
  return document.querySelector(AGE_GATE_CONFIG).dataset.errorPage;
}

const getAgeGateAgeLimit = () => {
  return document.querySelector(AGE_GATE_CONFIG).dataset.ageLimit;
}

const onErrorPage = () => {
  const currentPath = window.location.pathname;
  return currentPath.startsWith(getAgeGateErrorPage());
}

const redirectToErrorPage = () => {
  const newUrl = new URL(getAgeGateErrorPage(), window.location.origin);
  window.location.replace(newUrl.href);
}

const showAgeGateContainer = () => {
  document.querySelector(AGE_GATE_CONTAINER).classList.remove(AGE_GATE_HIDDEN);
  document.querySelector(HTML_TAG).classList.add(MAIN_SCROLLER_CLASS);
}

const hideAgeGateContainer = () => {
  document.querySelector(AGE_GATE_CONTAINER).classList.add(AGE_GATE_HIDDEN);
  document.querySelector(HTML_TAG).classList.remove(MAIN_SCROLLER_CLASS);
}

const isOneTrustTest = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(ONE_TRUST_SCAN) && (urlParams.get(ONE_TRUST_SCAN) === 'true');
}

const setDayField = () => {
  document.querySelector(DAY_SELECTOR)
    .setAttribute("maxlength", "2"); 

  document.querySelector(DAY_SELECTOR)
    .addEventListener("input", (event) => {
    if (event.target.value < 1 || event.target.value > 31) event.target.value = "";
  }); 
}

const setMonthField = () => {
  document.querySelector(MONTH_SELECTOR)
    .setAttribute("maxlength", "2");

  document.querySelector(MONTH_SELECTOR)
    .addEventListener("input", (event) => {
    if (event.target.value < 1 || event.target.value > 12) event.target.value = "";
  });
}

const setYearField = () => {
  document.querySelector(YEAR_SELECTOR)
    .addEventListener("blur", (event) => {
      if (event.target.value < 1900 || event.target.value > 2100) event.target.value = "";
    });

  document.querySelector(YEAR_SELECTOR)
    .addEventListener("input", (event) => {
      if (event.target.value.length > 4) event.target.value = "";
    });
}

const getDateValue = (selector) => {
  return document.querySelector(selector).value;
}

const validateAge = () => {
  const minAge = getAgeGateAgeLimit();
  const dob = new Date(getDateValue(YEAR_SELECTOR), getDateValue(MONTH_SELECTOR), getDateValue(DAY_SELECTOR));
  let minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - minAge);
  return !!(Date.parse(dob) < Date.parse(minDate));
}

const setSubmitHandler = () => {
  document.querySelector(SUBMIT_SELECTOR)
    .addEventListener("click", () => {
      if (validateAge()) {
        setAgeGateSession(true);
        hideAgeGateContainer();
        return;
      }

      setAgeGateSession(false);
      redirectToErrorPage();
    });
}

const initAgeGate = () => {
  setDayField();
  setMonthField();
  setYearField();
  setSubmitHandler();
  showAgeGateContainer();
}

const init = () => {
  if (onErrorPage()) return;

  if (hasFailedAgeGate()) {
    redirectToErrorPage();
    return;
  }

  if (!hasAgeGate()) return;

  if (hasPassedAgeGate()) return;

  if (isOneTrustTest()) return;

  initAgeGate();
}

if (document.readyState !== 'loading') {
  init();
} else {
  document.addEventListener("DOMContentLoaded", init);
}