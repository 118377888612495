function doInsta() {
  let feedContainer = feed.parentNode;
  feed.innerHTML = "";

  let feedImages = document.createElement("div");
  feedImages.className = "feed-images";
  feed.appendChild(feedImages);

  function makeSquare(element) {
    if (element) {
      var width = element.offsetWidth;
      var maxHeight = window.innerHeight * 0.7; // Maximum height of 70vh

      // Check if viewport width is larger than 991px and element's height is at its maximum
      if (window.innerWidth > 991 && element.offsetHeight >= maxHeight) {
        // Reduce the width to make the element square
        var newWidth = Math.min(width, maxHeight);
        element.style.width = newWidth + "px";
        element.style.height = newWidth + "px";
      } else {
        // Set the height equal to the width to make the element square
        element.style.height = width + "px";
      }
    } else {
      console.error("Element with ID '" + elementId + "' not found.");
    }
  }

  function appendInstaImages() {
    if (feedImages) {
      for (var i = 0; i < 9; i++) {
        let instaImage = document.createElement("div");
        instaImage.className = "insta-image";

        let imgContainer = document.createElement("div");
        imgContainer.className = "img-container";
        instaImage.appendChild(imgContainer);

        let imgElement = document.createElement("img");
        // imgElement.src = "/resources/images/insta-placeholder.png";
        imgElement.src = "/content/dam/mms/us/en/insta-placeholder.png";
        imgContainer.appendChild(imgElement);

        feedImages.appendChild(instaImage);
      }
    } else {
      console.error("Element with ID 'feed' not found.");
    }
  }

  // const feedContainer = document.querySelector(
  //   "#about-insta .page-section-full > .cmp-container > .aem-Grid > .aem-GridColumn > .cmp-container > .container"
  // );

  // Define the options for the Intersection Observer
  const observerOptions = {
    root: feedContainer,
    threshold: 1.0, // Fully intersecting
  };

  // Create the Intersection Observer
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const feed = entry.target;
      if (entry.isIntersecting) {
        // Element overflows its container
        makeSquare(feed);
      }
    });
  }, observerOptions);

  // Start observing the feed element
  if (feed) {
    observer.observe(feed);
  }

  window.addEventListener("resize", () => {
    makeSquare(feed);
  });
  makeSquare(feed);
  appendInstaImages();
}

let feed = document.querySelector("#feed");
if (feed) {
  doInsta(feed);
}