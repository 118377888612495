let navbarToggle = document.querySelector(".navbar-toggle");
let navbarMenu = document.querySelector(".nav-container");
let menuItemDropdownToggle = document.querySelector(
  ".nav-container .li-dropdown"
);
let menuItemDropdownToggleLink = document.querySelector(
  ".nav-container .li-dropdown > a"
);

let navLinks = document.querySelectorAll(".nav-container a");

navbarToggle.addEventListener("click", function () {
  if (navbarToggle.classList.contains("collapsed")) {
    navbarToggle.classList.remove("collapsed");
    navbarToggle.classList.add("open");
    navbarMenu.classList.add("open");
  } else {
    navbarToggle.classList.add("collapsed");
    navbarToggle.classList.remove("open");
    navbarMenu.classList.remove("open");
    menuItemDropdownToggle
      .querySelector(".ul-dropdown")
      .classList.remove("open");
  }
});

menuItemDropdownToggleLink.addEventListener("click", function (e) {
  if (navbarMenu.classList.contains("open")) {
    e.preventDefault();
    if (!menuItemDropdownToggle.classList.contains("open")) {
      menuItemDropdownToggle.classList.add("open");
      menuItemDropdownToggle
        .querySelector(".ul-dropdown")
        .classList.add("open");
    } else {
      menuItemDropdownToggle.classList.remove("open");
      menuItemDropdownToggle
        .querySelector(".ul-dropdown")
        .classList.remove("open");
    }
  }
});

window.addEventListener("resize", function () {
  if (window.innerWidth <= 991) {
    if (!navbarMenu.classList.contains("collapsed")) {
      navbarMenu.classList.add("collapsed");
      navbarToggle.classList.add("collapsed");
      navbarToggle.classList.remove("open");
    }
  }
});
