import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

if (!document.querySelector('.aem-AuthorLayer-Edit')) {
  let getRatio = (el) => window.innerHeight / (window.innerHeight + el.offsetHeight);

  gsap.utils.toArray(".bg-fixed").forEach((section, i) => {
    section.bg = section.querySelector(".cmp-container");

    gsap.fromTo(section.bg, {
      backgroundPosition: () => i ? `50% ${-window.innerHeight * getRatio(section)}px` : "50% 0px"
    }, {
      backgroundPosition: () => `50% ${window.innerHeight * (1 - getRatio(section))}px`,
      ease: "sine.inOut",
      scrollTrigger: {
        trigger: section, 
        start: () => i ? "top bottom" : "top top",
        end: "bottom top",
        scrub: true,
        invalidateOnRefresh: true
      }
    });
  });
}